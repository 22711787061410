<template>
  <v-main style="margin-left: 260px;">
    <router-view />

  </v-main>
</template>

<script>
  export default {
    name: 'DashboardCoreView',

    components: {

    },
  }
</script>
